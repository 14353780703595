import "./Footer.scss";
import React from "react";
import {FaLocationArrow , FaMobileAlt , FaEnvelope} from "react-icons/fa"
import Payment from '../../assets/payments.png'
const Footer = () => {
    return <footer className="footer">
        <div className="footer-content">
            <div className="col">
                <div className="title">About</div>
                <div className="text">Voluptatem accunsantium doloremeque laudantium</div>
            </div>
            <div className="col">
                <div className="title">Contact</div>
                <div className="c-item">
                    <FaLocationArrow/>
                    <div className="text">Kayaloram Rd , Punnamada Kuttankulangara , 68806</div>
                </div>
                <div className="c-item">
                    <FaMobileAlt/>
                    <div className="text">Phone: 0471 272 0261</div>
                </div>
                <div className="c-item">
                    <FaEnvelope/>
                    <div className="text">Email: test@gmail.com</div>
                </div>
            </div>
            <div className="col">
            <div className="title">Categories</div>
            <div className="text">Headphones</div>
            <div className="text">Smart Watches</div>
            <div className="text">Bluetooth Speakers</div>
            <div className="text">Wireless Airbuds</div>
            <div className="text">Home Theatre</div>
            <div className="text">Projectors</div>
            </div>
            <div className="col">
            <div className="title">Pages</div>
            <div className="text">Home</div>
            <div className="text">About</div>
            <div className="text">Privacy Police</div>
            <div className="text">Returns</div>
            <div className="text">Terms & Conditions</div>
            <div className="text">Contact Us</div>
            </div>
        </div>
        <div className="bottom-bar">
            <div className="bottom-bar-content">
                <div className="text">
                    UMERSTORE 2023 CREATED BY Umer. PREMIUM E-COMMERCE SOLUTIONS 
                </div>
                <img src={Payment} alt=""></img>
            </div>
        </div>
    </footer>;
};

export default Footer;
